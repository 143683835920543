// var apiBaseUrl = 'https://info-test.wwjrxx.com'; //测试环境
var apiBaseUrl = ''; //dev环境
var _GlobalConfig = {
    api: {
        sendSms: apiBaseUrl + '/api/send-sms', //发送验证码
        sendSms2: apiBaseUrl + '/api/common/send-sms', //获取验证码2
        verifyCode: apiBaseUrl + '/api/verify-code', //校验短信验证码
        pay: apiBaseUrl + '/api/pay.html', //支付
        order: apiBaseUrl + '/api/order', //查询订单
        copyLink: apiBaseUrl + '/api/copyLink', //复制微信号成功的接口
        checkOrder: apiBaseUrl + '/api/check/order', // 
        professionalLevels: apiBaseUrl + '/api/professionalLevels', // 获取等级
        paySign: apiBaseUrl + '/api/gzh/paySign', // 公众号支付
        binMobile: apiBaseUrl + '/api/gzh/binMobile', // 绑定手机号
        getOpenid: apiBaseUrl + '/api/gzh/getOpenid', // 获取openid
        toDouYin: apiBaseUrl + '/api/ocean/active', //上报
        xcxUrl: apiBaseUrl + '/api/xcx/url', // 获取跳转小程序的地址
        signIn: apiBaseUrl + '/api/signIn',
        share: apiBaseUrl + '/api/linkShare',
        xcxUrlLink: apiBaseUrl + '/api/sys/xcx/scheme',
        xcxActiveId: apiBaseUrl + '/api/xcx/active/id',
        classLeader: apiBaseUrl + '/api/check/order/classLeader',
        classLeaderQrCode: apiBaseUrl + '/api/order/getClassLeaderQrCode',
        randomNumber: apiBaseUrl + '/api/random/numb',
        randomHistory: apiBaseUrl + '/api/random/history',
        envValue: apiBaseUrl + '/api/sys/envValue', // 获取系统环境
        orderCount: apiBaseUrl + '/api/order/count', //获取流量订单渠道为0的总数
        addXCXUrl: apiBaseUrl + '/api/sys/xcx/scheme', //s生成小程序URL
        openWxCustomer: apiBaseUrl + '/api/qw/kf/getUrl', //生成微信客服URL
    },
    wexin: '无为投教课堂', //购买成功后用于复制粘贴的微信号
    carousel: `<p>152****6681&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>158****6688&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>186****6736&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>150****6291&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>138****1194&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>156****0212&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>139****1386&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p >158****8585&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>133****6347&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>139****1386&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>186****3459&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>185****9992&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>135****7510&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>186****6066&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>187****1967&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>188****5458&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;11秒前</p>
    <p>150****5153&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>135****9329&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>155****8258&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;11秒前</p>
    <p>151****3513&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>173****8070&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>186****0191&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>150****4599&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>152****8555&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>156****1505&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>186****1578&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>156****1505&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>150****3316&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>180****2315&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>182****8886&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>189****4628&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>136****9369&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>152****7918&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>189****2182&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>153****5653&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>151****7610&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>134****5348&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>182****1307&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>180****1740&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;11秒前</p>
    <p>159****7043&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>188****2835&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>138****8255&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>150****3522&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>181****9431&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>186****6211&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>131****8173&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>157****6756&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>187****8957&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>136****2910&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>151****7727&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>136****2910&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>182****7730&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;11秒前</p>
    <p>186****6928&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>158****2075&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>181****5388&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>189****9299&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>135****9922&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;11秒前</p>
    <p>133****9596&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>137****1231&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>153****0930&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>189****4667&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>135****2068&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>138****1085&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>138****8823&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>153****5985&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>181****1960&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>136****2119&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>134****2951&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>134****2951&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;11秒前</p>
    <p>181****9592&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>156****5050&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>159****0711&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>159****0711&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>139****1673&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>150****4624&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>158****5591&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>153****8291&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>188****7743&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>181****0232&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>176****9312&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>131****0095&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>132****3017&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>137****1418&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>156****1996&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>135****6414&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;9秒前</p>
    <p>159****4848&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>139****9627&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>183****0221&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>138****8452&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;11秒前</p>
    <p>150****5265&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;7秒前</p>
    <p>189****9533&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>152****4232&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>187****1677&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;6秒前</p>
    <p>138****7989&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>155****5755&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>158****6940&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;8秒前</p>
    <p>186****7360&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>
    <p>151****7008&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;10秒前</p>
    <p>158****0503&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;13秒前</p>
    <p>136****2593&nbsp;&nbsp;&nbsp;&nbsp;报名成功&nbsp;&nbsp;&nbsp;&nbsp;12秒前</p>`
};

module.exports = _GlobalConfig;