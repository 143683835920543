require('./alert')
var _GlobalConfig = require("./config");

var publicFn = {
    isWeixin: function () {
        return isWeixin()
    },
    hideAuthorizationButton: function () {
        $('#mb_box').hide()
        $('#mb_con').hide()
    },
    addAuthorizationButton: function () {
        if (isWeixin()) {
            if (!GetUrlParam("code")) {
                $('body').append('<div id="mb_box">' +
                    ' </div>' +
                    '<div id="mb_con">' +
                    '<div id="mb_msg">欢迎关注无为投教课堂！</div>' +
                    '<div id="mb_btnbox1">' +
                    '  <input id="mb_btn_ok1" type="button" value="点击授权">' +
                    '</div>' +
                    '</div>')
            } else {
                this.hideAuthorizationButton()
            }
        }
    },
    getAuthorization: function () {
        if (isWeixin()) {
            if (GetUrlParam("code")) {
                this.logo()
            }
            $('#mb_btn_ok1').on('click', function () {
                if (!GetUrlParam("code")) {
                    if (isWeixin()) {
                        if (!localStorage.getItem("code")) {
                            // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc1969453454a5216&redirect_uri=${encodeURIComponent(location.href)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redire`;
                            window.location.href = `/getcode.php?auk=${encodeURIComponent(location.href)}&scope=snsapi_userinfo`
                        }
                    }
                }
            })
        }
    },
    getOpenId: function () {
        var singIn = sessionStorage.getItem('signIn');
        let openId = null
        if (singIn) {
            var sing = JSON.parse(singIn);
            var singdata = sing['data'];
            if (typeof singdata == 'object') {
                console.log('data', singdata)
                if (JSON.stringify(singdata) != '{}') {
                    console.log('data', singdata)

                    exclusiveId = singdata['exclusiveId'] || ''
                    openId = singdata['openId'] || ''
                }
            }

        }
        return openId
    },
    logo: function () {
        var flag = isWeixin();
        // myAlert('flag',flag)
        if (flag) {
            var code = GetUrlParam("code");
            var exclusiveId = GetUrlParam("exclusiveId") || '';
            var _this = this;
            $.ajax({
                type: "post",
                url: _GlobalConfig.api.signIn,
                data: JSON.stringify({ gzhCode: code, exclusiveId: exclusiveId }),
                dataType: 'JSON',
                contentType: 'application/json',
                success: function (data) {
                    console.log('data', data)
                    // myAlert('data',data)
                    console.log(data['msg']);
                    if (data['code'] == 0) {
                        var res = data['data']
                        openId = res['openId'] || ''
                        exclusiveId = res['exclusiveId'] || ''
                        _this.hideAuthorizationButton()
                        sessionStorage.setItem('signIn', JSON.stringify(data))
                        _this.share()


                    } else if (data['code'] == 500) {

                        var wuwei_channel = GetUrlParam("wuwei_channel")
                        var course_id = GetUrlParam("course_id");
                        var pId = GetUrlParam("pId")
                        const isHref = GetUrlParam('wuwei_ishref')
                        const named = GetUrlParam('named')
                        var isInvaite = GetUrlParam("isInvaite")

                        var currentUrl = location.origin + location.pathname + '?wuwei_channel=' + wuwei_channel + '&course_id=' + course_id;
                        if (pId) {
                            currentUrl = currentUrl + '&pId=' + pId
                        }
                        if (exclusiveId) {
                            currentUrl = currentUrl + '&exclusiveId=' + exclusiveId
                        }
                        if (isHref) {
                            currentUrl = currentUrl + '&wuwei_ishref=' + isHref
                        }
                        if (named) {
                            currentUrl = currentUrl + '&named=' + named
                        }
                        if (isInvaite) {
                            currentUrl = currentUrl + '&isInvaite=' + named
                        }
                        window.location.href = `/getcode.php?auk=${encodeURIComponent(currentUrl)}&scope=snsapi_userinfo`

                    } else {
                        myAlert('系统提示', data['msg'])
                    }
                },
                error: function (err) {
                    myAlert('系统提示', '登录失败')
                },
            });
        } else {
            console.log('不在微信')
        }
    },
    share: function () {
        var url = window.location.href;
        $.ajax({
            type: "post",
            url: _GlobalConfig.api.share,
            data: url,
            dataType: 'JSON',
            contentType: 'application/json',
            success: function (data) {
                var singIn = sessionStorage.getItem('signIn');
                if (singIn) {
                    var sing = JSON.parse(singIn);
                    var singdata = sing['data'];
                    if (typeof singdata == 'object') {
                        console.log('data', singdata)
                        if (JSON.stringify(singdata) != '{}') {
                            console.log('data', singdata)

                            exclusiveId = singdata['exclusiveId'] || ''
                            openId = singdata['openId'] || ''
                        }
                    }

                }
                if (data['code'] != 0) {
                    myAlert('系统提示', data['msg'])
                    return
                }
                var res = JSON.parse(data['data'] || {})
                console.log(res)
                console.log(data['msg']);
                let config = {
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    // signature:res['signature'],
                    // appId:res['appId'],
                    // nonceStr:res['nonceStr'],
                    // timestamp:res['timestamp'],
                    jsApiList: [
                        "updateAppMessageShareData",
                        "updateTimelineShareData",
                        "onMenuShareAppMessage"
                    ], // 必填，需要使用的JS接口列表
                }
                var wuwei_channel = GetUrlParam("wuwei_channel")
                var course_id = GetUrlParam("course_id");
                var pId = GetUrlParam("pId")
                const isHref = GetUrlParam('wuwei_ishref')
                const named = GetUrlParam('named')


                var currentUrl = location.origin + location.pathname + '?wuwei_channel=' + wuwei_channel + '&course_id=' + course_id;
                if (pId) {
                    currentUrl = currentUrl + '&pId=' + pId
                }
                if (exclusiveId) {
                    currentUrl = currentUrl + '&exclusiveId=' + exclusiveId
                }
                if (isHref) {
                    currentUrl = currentUrl + '&wuwei_ishref=' + isHref
                }
                if (named) {
                    currentUrl = currentUrl + '&named=' + named
                }
                let shareUrl = currentUrl

                $.extend(config, res)

                if (data['code'] == 0) {
                    console.log('config', config)
                    wx.config(config);
                    wx.checkJsApi({
                        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", "onMenuShareAppMessage"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                        success: (res) => {
                            console.log('res', res)
                            // 以键值对的形式返回，可用的api值true，不可用为false
                            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                            wx.ready(function () {

                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData({
                                    title: document.title, //'理财进阶实操训练课', // 分享标题
                                    link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                    imgUrl: 'https://dstatic.wwjrxx.com/image/static/share_logo.jpg', // 分享图标
                                    desc: '点击查看详情',
                                    success: function (ok) {
                                        // 设置成功
                                        console.log('updateAppMessageShareData error', ok)

                                    },
                                    fail: (error) => {
                                        console.log('updateAppMessageShareData error', error)
                                        wx.onMenuShareAppMessage({
                                            title: document.title, //'理财进阶实操训练课', // 分享标题
                                            link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                            imgUrl: 'https://dstatic.wwjrxx.com/image/static/share_logo.jpg', // 分享图标
                                            desc: '点击查看详情',
                                            success: function () {
                                                // 设置成功
                                                console.log("onMenuShareAppMessage ok");
                                            },
                                            fail: function (error) {
                                                console.log('onMenuShareAppMessage error', error)

                                            },
                                        });


                                    }

                                });
                            });
                            wx.ready(() => {
                                wx.updateTimelineShareData({
                                    title: document.title, //'理财进阶实操训练课', // 分享标题
                                    link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                    imgUrl: 'https://dstatic.wwjrxx.com/image/static/share_logo.jpg', // 分享图标
                                    desc: '点击查看详情',
                                    success: function () {
                                        // 设置成功
                                        console.log(" updateTimelineShareData ok");
                                    },
                                    fail: function (error) {
                                        console.log('updateTimelineShareData error', error)

                                    },

                                });
                            })



                        },
                    });
                } else {
                    myAlert('系统提示', data['msg'])
                }
            },
            error: function (err) {
                myAlert('系统提示', '登录失败')
            },
        });
    },
    isZiJie: function () {
        return navigator.userAgent
            .toLowerCase()
            .includes("toutiaomicroapp")
    },
    postZiJie: function (data) {
        const url = `../pay/pay?payDataStr=${encodeURIComponent(JSON.stringify(data))}`;
        tt.miniProgram.navigateTo({
            url: url,
        });
        // tt.miniProgram.postMessage({
        //   data: {
        //     mes: JSON.stringify(data),
        //   },
        // });
    }
}
module.exports = publicFn;

function changeURLArg(url, arg, arg_val) {
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        if (url.match('[\?]')) {
            return url + '&' + replaceText;
        } else {
            return url + '?' + replaceText;
        }
    }
    // return url+'\n'+arg+'\n'+arg_val; 
}

function isWeixin() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1
}
function GetUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
