module.exports = function () {
    // 页面顶部倒计时 js
    //倒计时js  包括活动截止时间
    var today = new Date();
    var hours = today.getHours();
    // var hours =1;
    var minutes = today.getMinutes();
    // var minutes =51;
    var seconds = today.getSeconds();
    var xiaoshi = $(".xiaoshi");
    var shangwu = $(".shangwu");
    var currTime = hours * 3600 + minutes * 60 + seconds;
    if (minutes > 50) {
        // xiaoshi = hours+2;
        var ap = hours + 2;
        if (ap >= 6 && ap <= 11) {
            shangwu.html("上午");
        } else if (ap > 11 && ap <= 12) {
            shangwu.html("中午");
        } else if (ap > 12 && ap <= 18) {
            shangwu.html("下午");
        } else if (ap > 18 && ap <= 24) {
            shangwu.html("晚上");
        } else {
            xiaoshi.html(hours + 2);
        }
        xiaoshi.html(hours + 2);
        var hoverHtml = xiaoshi.html();
        var lastTime = hoverHtml * 60 * 60;
        // var delTime = lastTime-currTime
        if (hours == 22) {
            xiaoshi.html("0");
            lastTime = 24 * 60 * 60;
        }
        if (hours == 23) {
            xiaoshi.html("1");
            lastTime = 25 * 60 * 60;
        }
        if (hours == 24 || hours == 0) {
            xiaoshi.html("2");
            lastTime = 26 * 60 * 60;
        }
    } else {
        // xiaoshi = hours+1;
        var ap = hours + 1;
        if (ap >= 6 && ap <= 11) {
            shangwu.html("上午");
        } else if (ap > 11 && ap <= 12) {
            shangwu.html("中午");
        } else if (ap > 12 && ap <= 18) {
            shangwu.html("下午");
        } else if (ap > 18 && ap <= 24) {
            shangwu.html("晚上");
        } else {
            xiaoshi.html(hours + 1);
        }
        xiaoshi.html(hours + 1);
        var hoverHtml = xiaoshi.html();
        var lastTime = hoverHtml * 60 * 60;
        // var delTime = lastTime-currTime
        if (hours == 23) {
            xiaoshi.html("0");
            lastTime = 24 * 60 * 60;
        }
        if (hours == 24 || hours == 0) {
            xiaoshi.html("1");
            lastTime = 25 * 60 * 60;
        }

    }
    var delTime = lastTime - currTime;
    console.log("delTime:" + delTime);
    console.log("lastTime:" + lastTime);
    console.log("currTime:" + currTime);
    console.log("hoverHtml:" + hoverHtml);
    console.log(hours + ":" + minutes + ":" + seconds);

    var spans = $(".timeout span");
    if(spans.length===0){
        return 
    }
    var total = delTime;
    var timeid = setInterval(function () {
        total--;
        if (total < 0) {
            clearInterval(timeid);
            return;
        }
        var minute = Math.floor(total / 60);
        // console.log(minute);
        var second = Math.floor(total % 60);
        spans[0].innerHTML = Math.floor(minute / 10);
        spans[1].innerHTML = Math.floor(minute % 10);
        spans[2].innerHTML = Math.floor(second / 10);
        spans[3].innerHTML = Math.floor(second % 10);

    }, 1000);
}