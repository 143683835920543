var _GlobalConfig = require("./config");

export function getXcxUrl(phone) {
    // window.location.href = 'result.html'
    $.ajax({
        type: "GET",
        url: navigator.userAgent.indexOf('UCBrowser') > -1? 'https://'+location.host + _GlobalConfig.api.xcxUrl : _GlobalConfig.api.xcxUrl,
        data: {
            mobile: phone,
        },
        success: function (data) {
            window.location.href = data.data
        },
        error: function (err) {    
           alert('未知错误')
        },
    });
}