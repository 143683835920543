import "../styles/index.css";
var _GlobalConfig = require("./config");
var _PublicFn = require("./publicFn");

// var stat = require('./stat');
require('./alert')
import { getXcxUrl } from './common'
import publicFn from "./publicFn";
var countDown = require("./countDown");
var carousel = require("./carousel");
var countintervalt = null;
var openId = null
var exclusiveId = null;
var isInvaite = undefined
//  页面顶部倒计时 调用
countDown();
carousel();
$(function () {
    if (!GetUrlParam("code")) {
        if (navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1) {
            if (!localStorage.getItem("code")) {
                // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc1969453454a5216&redirect_uri=${encodeURIComponent(location.href)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redire`;
                window.location.href = `/getcode.php?auk=${encodeURIComponent(location.href)}&scope=snsapi_userinfo`
            }
        }
    } else {
        _PublicFn.logo()
    }
    let imgs = $('.link_config_img');
    $.each(imgs,function(index,item){
        item['src'] = item['src'] +'?t='+Math.random()
    })
    showInviteCode()
    //微信内置浏览器浏览H5页面弹出的键盘遮盖文本框的解决办法 
    window.addEventListener("resize", function () {
        if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {
            window.setTimeout(function () {
                document.activeElement.scrollIntoViewIfNeeded();
            }, 0);
        }
    })
})
function showInviteCode() {
    isInvaite = GetUrlParam("isInvaite")
    if (isInvaite === '1') {
        $('.inviteCode').show()
    }
}
function changeURLArg(url, arg, arg_val) {
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        if (url.match('[\?]')) {
            return url + '&' + replaceText;
        } else {
            return url + '?' + replaceText;
        }
    }
    // return url+'\n'+arg+'\n'+arg_val; 
}

function isWeixin() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1
}
function GetUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}



function getCookie(c_name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) c_end = document.cookie.length;
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}
//验证微信是否支付
var WxPayPhone = getCookie("WxPayPhone");
var wx_redirect_phone = sessionStorage.getItem("wx_redirect_phone");
var setIntervalTimer = null;
var intervalCount = 0;
if (WxPayPhone && wx_redirect_phone && WxPayPhone == wx_redirect_phone) {
    setIntervalTimer = setInterval(function () {
        $.ajax({
            type: "GET",
            url: navigator.userAgent.indexOf('UCBrowser') > -1 ? 'https://' + location.host + _GlobalConfig.api.order : _GlobalConfig.api.order,
            data: {
                mobile: WxPayPhone,
                channel: GetUrlParam('wuwei_channel'),
                courseId: GetUrlParam('course_id') || 2,
            },
            success: function (data) {
                intervalCount++;
                if (intervalCount >= 100) {
                    clearInterval(setIntervalTimer);
                }
                if (data.code == 0) {
                    clearInterval(setIntervalTimer);
                    $('.pay-sure').attr('canclick', 'true')
                    // if(GetUrlParam('wuwei_channel')==1) { // 1是抖音
                    //   let url = encodeURIComponent(window.location.href);
                    //   $.ajax({
                    //     type: "POST",
                    //     contentType: 'application/json;charset=UTF-8',
                    //     url: _GlobalConfig.api.toDouYin,
                    //     data: JSON.stringify({ link: url, event_type: 2 }),
                    //     success: function (msg) {
                    //       console.log(msg);
                    //     },
                    //   });
                    // }
                    // if(GetUrlParam('wuwei_channel')==3) { // 腾讯
                    //   gdt('track', 'PURCHASE');// 付费
                    // }
                    setTimeout(() => {
                        // window.location.href = "result.html";   
                        if (window.location.pathname === '/ld_yiyuangou.html' || window.location.pathname === '/ld_jiuyuangou.html') {
                            payToWx(WxPayPhone)
                        } else {
                            getXcxUrl(WxPayPhone)
                        }
                    }, 0);
                }
            },
            error: function (err) {
                intervalCount++;
                if (intervalCount > 5) {
                    clearInterval(setIntervalTimer);
                }
            },
        });
    }, 2000);
}

// isCheck();
// //验证码是否正确
// function isCheck() {
//     var check = $("#check").val();
//     var payType = get_cancel_pay_intercept_cookie();
//     if (payType == 'wx' || payType == 'zfb') {
//         showCancelPayPop();
//         return;
//     }
//     if (check == '1') {
//         $("#phone").focus();
//         $(".mask").show();
//     }
// }
// 验证码 js
var time2 = 60;
var count1 = time2;
var button2 = $("#password-btn");
// 需引入 zepto 使用tao 事件
var isClickGetCode = true;
$("#password-btn").on("click", function () {
    if (isClickGetCode) {
        var phone = $("#phone").val();
        if (phone == "") {
            myAlert('系统提示', '请先输入手机号');
            $("#phone").focus();
            return false;
        }
        if (!/^1[3456789]\d{9}$/.test(phone)) {
            $(".tishi2").show();
            return false;
        }
        console.log('url', navigator.userAgent)
        $.ajax({
            type: "GET",
            url: navigator.userAgent.indexOf('UCBrowser') > -1 ? 'https://' + location.host + _GlobalConfig.api.sendSms : _GlobalConfig.api.sendSms, // 这里是解决在uc里面发不起请求
            data: {
                mobile: phone,
                courseId: GetUrlParam('course_id') || 2,
            },
            success: function (data) {
                if (data.code !== 0) {
                    myAlert('系统提示', data.msg);
                    return;
                } else {
                    myAlert('系统提示', '验证码已发送');
                    isClickGetCode = false;
                    countintervalt = setInterval(timecount1, 1000);
                    button2.attr("disabled", true);
                    setTimeout(function () {
                        button2.attr("class", "");
                    }, 1000);
                }
            },
            error: function (data) {
                // 成功返回的结果(响应)
                console.log('codeerrordata', JSON.stringify(data));
            },
        });

    }
});

function timecount1() {
    button2.text(count1 + "s");
    if (count1 <= 0) {
        count1 = time2;
        clearInterval(countintervalt);
        button2.text("重新获取");
        button2.attr("disabled", false);
        button2.attr("class", "password-btn");
        isClickGetCode = true;
    } else count1--;
}

var flag = true;
//  立即报名 js
var hasClick = false;
const checkArr = ['lifusix', 'chenbowenkuaishou', 'chenbowen']
$(".baoming").click(function () {
    if (window.location.href.includes('ksthree_buy299') || window.location.href.includes('lifusix') || window.location.href.includes('chenbowenkuaishou') || window.location.href.includes('kuaishou_nine_one') || window.location.href.includes('st_shares_nine.html')) {
        if (!$(".check").is(":checked")) {
            $('input').blur()
            // alert('阅读并勾选个人信息保护声明')
            myAlert('系统提示', '阅读并勾选个人信息保护声明');
            return
        }
    }
    if ($('.pay-sure').attr('canclick') == 'true') {
        // 设置input向上
        var phone = $("#phone").val();
        var code = $("#yanzhengma").val();
        var name = $('#name') && $('#name').val() || ''
        var selectArea = $('#selectArea') && $('#selectArea').val() || '';
        var detailedAddress = $('#detailedAddress') && $('#detailedAddress').val() || ''
        var inviteCode = $('#inviteCode') && $('#inviteCode').val() || ''
        var href = window.location.href
        if (phone == "") {
            $("#phone").focus();
            return false;
        }
        if (!/^1[3456789]\d{9}$/.test(phone)) {
            $(".tishi2").show();
            return false;
        }
        if (code == "") {
            $(".tishi3").show();
            return false;
        }
        if (hasClick) {
            return;
        }

        if (isInvaite === '1') {
            if (!inviteCode) {
                $(".tishi10").show();
                return false;
            }
            if (!/^[0-9]*$/.test(inviteCode)) {
                $(".tishi11").show();
                return false;
            }
        }


        if (href.includes('new_gupiao_nine')) {
            if (name === '') {
                $(".tishi6").show();
                return false;
            }
            if (selectArea === '') {
                $(".tishi7").show();
                return false;
            }
            if (detailedAddress === '') {
                $(".tishi8").show();
                return false;
            }
        }
        hasClick = true;
        // if(GetUrlParam('wuwei_channel')==3) { // 腾讯在预约的阶段就上报
        //   gdt('track', 'RESERVATION');// 预约
        // }
        $('.pay-sure').attr('canclick', 'false')
        $.ajax({
            type: "GET", // 请求类型
            dataType: "json", // 数据返回类型
            url: navigator.userAgent.indexOf('UCBrowser') > -1 ? 'https://' + location.host + _GlobalConfig.api.verifyCode : _GlobalConfig.api.verifyCode, // 请求URL
            data: {
                verifyCode: code,
                mobile: phone,
                channel: GetUrlParam('wuwei_channel'),
                courseId: GetUrlParam('course_id') || 2,
                activeUrl: encodeURIComponent(window.location.href),
                name: name,
                address: selectArea + '' + detailedAddress,
                pId: GetUrlParam('pId'),
                inviteCode: inviteCode
            },
            success: function (data) {
                // 成功返回的结果(响应)
                hasClick = false;
                $('.pay-sure').attr('canclick', 'true')
                if (data.code == 0) {
                    //调起支付选择方式
                    if (navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1) {
                        // 如果是在微信内部直接支付
                        payFn("gzh");
                    } else if (publicFn.isZiJie()) {
                        payFn("byte");
                    } else {
                        $(".mask").show();
                    }
                } else if (data.code == 1) {
                    //已购买
                    // $(".tishi5").show();
                    // _hmt.push(["_trackPageview", "/result.html"]);
                    sessionStorage.setItem("wx_redirect_phone", phone);
                    setCookie("WxPayPhone", phone);
                    // location.href = "result.html";
                    if (window.location.pathname === '/ld_yiyuangou.html' || window.location.pathname === '/ld_jiuyuangou.html') {
                        payToWx(phone)
                    } else {
                        getXcxUrl(phone)
                    }
                } else {
                    myAlert('系统提示', data.msg);
                }
                // else if (data.code == 2) {
                //     //验证码错误
                //     $(".tishi3").show();
                // } else if (data.code == 3) {
                //     //超出错误次数
                //     $(".tishi4").show();
                // }
            },
            error: function (data) {
                // 成功返回的结果(响应)
                console.log('errordata', JSON.stringify(data));
                hasClick = false;
                $('.pay-sure').attr('canclick', 'true')

            },
        });
    }
});

// 放弃使用优惠券
// $(".giveUp").click(function () {
//     $(".discountMask").hide();
//     WMpoint("production", "click", "支付挽留弹窗", "106_toufang_lcl_0020", "");
// })

function isIos() {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        return false;
    }
    if (isiOS) {
        return true;
    }
}
// 支付
function payFn(type) {
    // type: wx | zfb;
    // set_cancel_pay_intercept_cookie(type);
    var phone = $("#phone").val();
    if (!/^1[3456789]\d{9}$/.test(phone)) {
        $(".tishi2").show();
        return false;
    }
    $.ajax({
        type: "GET",
        url: navigator.userAgent.indexOf('UCBrowser') > -1 ? 'https://' + location.host + _GlobalConfig.api.pay : _GlobalConfig.api.pay,
        data: {
            payType: type,
            channel: GetUrlParam('wuwei_channel'),
            mobile: phone,
            redirectUrl: location.href,
            openId: _PublicFn.getOpenId(),
            courseId: GetUrlParam('course_id') || 2,
            redirectUrlForGD: location.href,
        },
        error: function (xml, status, error) {
            $(".tishi6").show();
            return false

        },
        success: function (data) {
            // if(GetUrlParam('wuwei_channel')==3) { // 2021-11-3腾讯上报改成点击付费的时候上报
            //   gdt('track', 'RESERVATION');// 预约
            // }
            if (data) {
                sessionStorage.setItem("wx_redirect_phone", phone);
                setCookie("WxPayPhone", phone);
                $(".mask").hide();
                if (navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1) {
                    function onBridgeReady() {
                        let result = undefined;
                        if (typeof data == 'string') {
                            result = JSON.parse(data)
                        }
                        if (data.code == 500) {
                            myAlert(data.msg)
                        }
                        WeixinJSBridge.invoke(
                            "getBrandWCPayRequest",
                            result,
                            function (res) {
                                if (res.err_msg == "get_brand_wcpay_request:ok") {
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    // window.location.href = 'result.html'
                                    // if(GetUrlParam('wuwei_channel')==1) { // 1是抖音
                                    //   let url = encodeURIComponent(window.location.href);
                                    //   $.ajax({
                                    //     type: "POST",
                                    //     contentType: 'application/json;charset=UTF-8',
                                    //     url: _GlobalConfig.api.toDouYin,
                                    //     data: JSON.stringify({ link: url, event_type: 2 }),
                                    //     success: function (msg) {
                                    //       console.log(msg);
                                    //     },
                                    //   });
                                    // }
                                    // if(GetUrlParam('wuwei_channel')==3) { // 今日头条
                                    //   gdt('track', 'PURCHASE');// 付费
                                    // }
                                    setTimeout(() => {
                                        // window.location.href = "result.html";
                                        if (window.location.pathname === '/ld_yiyuangou.html' || window.location.pathname === '/ld_jiuyuangou.html') {
                                            payToWx(phone)
                                        } else {
                                            getXcxUrl(phone)
                                        }
                                    }, 0);
                                }
                            }
                        );
                    }
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener(
                                "WeixinJSBridgeReady",
                                onBridgeReady,
                                false
                            );
                        } else if (document.attachEvent) {
                            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
                        }
                    } else {
                        onBridgeReady();
                    }
                } else if (publicFn.isZiJie()) {

                    console.log('data', data)
                    console.log('data json', JSON.stringify(data))
                    publicFn.postZiJie(data);
                } else {
                    if (type === "wx") {
                        location.href =
                            data + "&redirect_url=" + window.encodeURIComponent(location.href);
                    } else {
                        $("body").html(data);
                    }
                }
            }
        },
    });
}

//  支付宝支付
$(".zfb").click(function () {
    payFn("zfb");
});
//  微信支付
$(".wx").click(function () {
    payFn("wx");
});

//  手机号/验证码 验证弹窗 js
$(".tishi2OK").click(function () {
    $(".tishi2").hide();
    $(".discountMask").hide();
    $("#phone").focus();
});
$(".tishi3OK").click(function () {
    $(".tishi3").hide();
});
$(".tishi4OK").click(function () {
    $(".tishi4").hide();
});
$(".tishi5OK").click(function () {
    $(".tishi5").hide();
});
$(".tishi6OK").click(function () {
    $(".tishi6").hide();
});
$(".tishi7OK").click(function () {
    $(".tishi7").hide();
});
$(".tishi8OK").click(function () {
    $(".tishi8").hide();
});
$(".tishi10OK").click(function () {
    $(".tishi10").hide();
});
$(".tishi11OK").click(function () {
    $(".tishi11").hide();
});
// $(".tishi6OK").click(function () {
//     $(".tishi6").hide();
// })
//  微信支付宝支付 选择弹窗
$(".guan").click(function () {
    $('.pay-sure').attr('canclick', 'true')
    $(".mask").hide();
});

$('#statement').click(function () {
    $('.statement').show()
})
$('.know').click(function () {
    $('.statement').hide()
})

function showCancelPayPop() {
    $(".discountMask").show();
    $(".mask").hide();
}

//  继续支付
// $(".pay_again").click(function () {
//     var payType = get_cancel_pay_intercept_cookie();
//     if (payType == 'wx') {
//         $(".wx").click();
//     }
//     if (payType == 'zfb') {
//         $(".zfb").click();
//     }
// });

// 设置支付方式 cookie
// function set_cancel_pay_intercept_cookie(payType) {
//     setCookie("pay_intercept_cookie", payType);3.7rem
// }
// // 获取支付方式 cookie
// function get_cancel_pay_intercept_cookie() {
//     return getCookie("pay_intercept_cookie");
// }
// setCookie
function setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + 10 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

//禁止双击双指放大
function disableScalable() {
    // 阻止双击放大
    var lastTouchEnd = 0;
    document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    });
    document.addEventListener(
        "touchend",
        function (event) {
            var now = new Date().getTime();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }
            lastTouchEnd = now;
        },
        false
    );

    // 阻止双指放大
    document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
    });
}
disableScalable();

// 付费跳转微信
function payToWx(phone) {
    $.ajax({
        type: "post",
        dataType: 'json',
        contentType: 'application/json',
        url:
            navigator.userAgent.indexOf("UCBrowser") > -1
                ? 'https://' + location.host + _GlobalConfig.api.addXCXUrl
                : _GlobalConfig.api.addXCXUrl,
        data: JSON.stringify({
            path: '/pages/pay_active/pay_active',
            query: `phone=${phone}`//`link=${encodeURIComponent(window.location.href)}`
        })
        ,
        success: function (data) {
            if (data.data) {
                window.location.href = data.data;
            } else {
                alert("错误:" + data.data);
            }
        },
        error: function (err) {
            alert("未知错误");
        },
    });
}
