(function($) {        
  $.alerts = {         
      alert: function(title, message, callback) {  
          if( title == null ) title = 'Alert';  
          $.alerts._show(title, message, null, 'alert', function(result) {  
              if( callback ) callback(result);  
          });  
      },  
         
      confirm: function(title, message, callback) {  
          if( title == null ) title = 'Confirm';  
          $.alerts._show(title, message, null, 'confirm', function(result) {  
              if( callback ) callback(result);  
          });  
      },  
             
        
      _show: function(title, msg, value, type, callback) {  
                  var _html = "";  
                  _html += '<div id="mb_box"></div><div id="mb_con">';  
                  _html += '<div id="mb_msg">' + msg + '</div><div id="mb_btnbox">';  
                    if (type == "alert") {  
                    _html += '<input id="mb_btn_ok" type="button" value="知道了" />';  
                  }  
                  if (type == "confirm") {  
                    _html += '<input id="mb_btn_no" type="button" value="取消" />';  
                    _html += '<input id="mb_btn_ok" type="button" value="确定" />';  
                  }  
                  _html += '</div></div>';  
                 
                  //必须先将_html添加到body，再设置Css样式  
                  $("body").append(_html); GenerateCss();  
         
          switch( type ) {  
              case 'alert':  
        
                  $("#mb_btn_ok").click( function() {  
                      $.alerts._hide();  
                      callback(true);  
                  });  
                  $("#mb_btn_ok").focus().keypress( function(e) {  
                      if( e.keyCode == 13 || e.keyCode == 27 ) $("#mb_btn_ok").trigger('click');  
                  });  
              break;  
              case 'confirm':  
                   
                  $("#mb_btn_ok").click( function() {   
                      $.alerts._hide();  
                      if( callback ) callback(true);  
                  });  
                  $("#mb_btn_no").click( function() {  
                      $.alerts._hide();  
                      if( callback ) callback(false);  
                  });  
                  $("#mb_btn_no").focus();  
                  $("#mb_btn_ok, #mb_btn_no").keypress( function(e) {  
                      if( e.keyCode == 13 ) $("#mb_btn_ok").trigger('click');  
                      if( e.keyCode == 27 ) $("#mb_btn_no").trigger('click');  
                  });  
              break;
          }  
      },  
      _hide: function() {  
           $("#mb_box,#mb_con").remove();  
      }  
  }  
  // Shortuct functions  
  myAlert = function(title, message, callback) {  
      $.alerts.alert(title, message, callback);  
  }  
     
  myConfirm = function(title, message, callback) {  
      $.alerts.confirm(title, message, callback);  
  };  
         
 
    
    //生成Css  
var GenerateCss = function () {  
 
  $("#mb_box").css({ width: '100%', height: '100%', zIndex: '99999', position: 'fixed',  
    filter: 'Alpha(opacity=60)', backgroundColor: 'black', top: '0', left: '0', opacity: '0.6'  
  });  
 
  $("#mb_con").css({ zIndex: '999999', width: '290px',height:'150px', position: 'fixed',  
    backgroundColor: 'White',  top:'50%',left:'50%',transform:'translate(-50%,-50%)',borderRadius: '6px'
  });  
 
  $("#mb_tit").css({ display: 'block', fontSize: '14px', color: '#444', padding: '10px 15px',  
    backgroundColor: '#fff', borderRadius: '15px 15px 0 0',  
    fontWeight: 'bold'  
  });  
 
  $("#mb_msg").css({ padding: '20px', lineHeight: '40px', textAlign:'center', 
    fontSize: '18px' ,color:'#4c4c4c',marginTop: '14px' 
  });  
 
  $("#mb_ico").css({ display: 'block', position: 'absolute', right: '10px', top: '9px',  
    border: '1px solid Gray', width: '18px', height: '18px', textAlign: 'center',  
    lineHeight: '16px', cursor: 'pointer', borderRadius: '12px', fontFamily: '微软雅黑'  
  });  
 
  $("#mb_btnbox").css({ textAlign: 'center', marginTop: '-36px', width: '100%', position: 'absolute',bottom: '0px' });  
  $("#mb_btn_ok,#mb_btn_no").css({ width: '80px', height: '50px', color: 'white', border: 'none', borderRadius:'4px'});  
  $("#mb_btn_ok").css({ background: 'linear-gradient(180deg,#ff929d,#ff0034)',fontSize:'17px', borderRadius:'0px',textIndent:0,lineHeight:0, width: '100%',borderBottomLeftRadius:'6px',borderBottomRightRadius: '6px' });  
  $("#mb_btn_no").css({ backgroundColor: 'gray', marginRight: '40px' });  
 
  //右上角关闭按钮hover样式  
  $("#mb_ico").hover(function () {  
    $(this).css({ backgroundColor: 'Red', color: 'White' });  
  }, function () {  
    $(this).css({ backgroundColor: '#DDD', color: 'black' });  
  });  
 
  // var _widht = document.documentElement.clientWidth; //屏幕宽  
  // var _height = document.documentElement.clientHeight; //屏幕高  
 
  // var boxWidth = $("#mb_con").width();  
  // var boxHeight = $("#mb_con").height();  
 
  //让提示框居中  
  // $("#mb_con").css({ top: (_height - boxHeight) / 2 + "px", left: (_widht - boxWidth) / 2 + "px" });  
}  
 

})(jQuery);