var _GlobalConfig = require('./config');

// 轮询及人数倒数 js
function initCarousel() {
    $('.carousel').append(_GlobalConfig.carousel)
}

function pollingAndpeopleNum() {
    var uw = $('.carousel').innerHeight()
    var time = 3000;
    var len = $('.carousel>p').length;
    var index = window.localStorage.getItem('INDEXI') || 1;
    var renshu = $(".num").html();
    $('.num').html(renshu)
    $('.carousel').css('transform', 'translateY(' + index * (-30) + 'px)')
    var t1 = null;
    if (renshu) {
        function showRenshu() {
            renshu = renshu - 1;
            if (renshu <= 3) {
                renshu = 3;
                console.log(renshu);
                clearInterval(setId);
                return false;
            }
            $(".num").html(renshu);
        }

        var setId = setInterval(showRenshu, time);
    }

    function fun() {
        if (index < len - 2) {
            index++;
            $('.carousel').css('transition', 'all 1s')
            $('.carousel').css('transform', 'translateY(' + index * (-30) + 'px)')

        }
        if (index == len - 2) {
            // haha++
            time = 0
            index = 0;
            $('.carousel').css('transition', 'all 0s')
            $('.carousel').css('transform', 'translateY(' + index * (-30) + 'px)')
        }
        window.localStorage.setItem('INDEXI', index);
        if (renshu) {
            if (renshu <= 3) {
                renshu = 3;
                console.log(renshu);
                clearInterval(setId);
                return false;
            }
        }

    }

    var TimeoutId = setInterval(fun, time);
}
module.exports = function () {
    initCarousel()
    pollingAndpeopleNum()
}